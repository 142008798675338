import React, { useContext } from 'react'
import { InfoContext } from './index'

function Info() {
  const { setIsInfoOpen } = useContext(InfoContext)

  const handleBackdropClick = () => {
    setIsInfoOpen(false)
  }

  const handleModalClick = (event) => {
    event.stopPropagation()
  }

  return (
    <div id="info" onMouseDown={handleBackdropClick}>
      <div className="modal" onMouseDown={handleModalClick}>
        <div id="close" onClick={handleBackdropClick}>
          x
        </div>
        <p>
          nattonooto is Roman{' '}
          <a href="http://romanrandom.com/" target="_blank">
            (Random)
          </a>{' '}
          Ljubimov and Pablo Escauriaza{' '}
          <a href="http://hibernando.com/" target="_blank">
            Hibernando
          </a>
          , who joined forces in Bilbao to improvise with electronic music and
          generative visuals.
        </p>
        <br />
        <p>
          The tracks available on this site are recordings of sessions,
          sketches, ideas and improvisations. Licensed under{' '}
          <a
            href="https://creativecommons.org/licenses/by-nc-sa/4.0/"
            target="_blank"
          >
            CC BY-NC-SA 4.0
          </a>
          .
        </p>
        <br />
        <p>nattonooto@romanrandom.com</p>
        <p>
          <a
            href="https://www.youtube.com/results?search_query=nattonooto"
            target="_blank"
          >
            youtube
          </a>
        </p>
        <p>
          <a href="https://www.instagram.com/nattonooto/" target="_blank">
            instagram
          </a>
        </p>
        <p>
          <a href="https://www.facebook.com/nattonooto" target="_blank">
            facebook
          </a>
        </p>
        <p>
          <a href="https://soundcloud.com/nattonooto" target="_blank">
            soundcloud
          </a>
        </p>
        <p>
          Web-crafting:{' '}
          <a href="http://wabisabit.com/" target="_blank">
            wabisabiT
          </a>
        </p>
      </div>
    </div>
  )
}

export default Info
