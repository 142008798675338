import React, { Fragment, useContext } from 'react'
import { PLAYED_TRACKS_KEY } from './constants'
import { PlayerContext } from './index'
import Track from './Track'

function Tracks({ tracks }) {
  const { audioState, currentTrack, handleTrackClicked } = useContext(
    PlayerContext,
  )

  let prevVersion = 0
  let renderVersion = null

  const playedTracks = JSON.parse(localStorage.getItem(PLAYED_TRACKS_KEY))

  return (
    <div id="tracks" className="admin">
      {tracks.map((track) => {
        const date = new Date(track.date)

        const currentVersion = track.version
        if (currentVersion !== prevVersion) {
          renderVersion = currentVersion
        } else {
          renderVersion = null
        }
        prevVersion = currentVersion

        const wasPlayed =
          playedTracks && playedTracks.indexOf(track.filename) > -1

        const isCurrent =
          currentTrack &&
          audioState !== -1 &&
          currentTrack.filename === track.filename

        return (
          <Fragment key={track.filename}>
            {renderVersion && <p className="version">{'v.' + renderVersion}</p>}
            <Track
              track={track}
              date={date}
              year={renderVersion}
              wasPlayed={wasPlayed}
              isCurrent={isCurrent}
              handleClicked={handleTrackClicked}
            />
          </Fragment>
        )
      })}
    </div>
  )
}

export default Tracks
