export const getTime = (seconds) => {
  if (typeof seconds !== 'number') {
    // TODO FIX: seconds is a Howl object semtiems
    return '0'
  }

  return new Date(1000 * seconds)
    .toISOString()
    .substr(11, 8)
    .replace(/^[0:]{1,4}/, '')
}
