import { Howl } from 'howler'
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import ReactDOM from 'react-dom'
import { PLAYED_TRACKS_KEY } from './constants'
import Info from './Info'
import PlayerBar from './PlayerBar'
import Tracks from './Tracks'
import trackList from './tracks.json'
import TracksAdmin from './TracksAdmin'
import Visuals from './Visuals'

const PLAYBACK_POSITION_UPDATE_INTERVAL = 50
const baseUrl = 'https://nattonooto.romanrandom.com/tracks'

export const PlayerContext = createContext()
export const SettingsContext = createContext()
export const InfoContext = createContext()

let audio = null

let intervalId

const App = () => {
  const [currentTrack, setCurrentTrack] = useState(null)
  const currentTrackRef = useRef(currentTrack)
  const [audioState, setAudioState] = useState(0) // 0, 1, 2 (Loading), -1 (Error)
  const [audioPosition, setAudioPosition] = useState(0) // %
  const [isInfoOpen, setIsInfoOpen] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(() => {
    const isAdminStored = localStorage.getItem('admin')
    if (isAdminStored != null) {
      setIsAdmin(JSON.parse(isAdminStored))
    }

    const keyListener = (event) => {
      if (event.code === 'KeyA' && event.altKey) {
        setIsAdmin((currentValue) => !currentValue)
      }
    }
    window.addEventListener('keydown', keyListener)
    return () => {
      window.removeEventListener('keydown', keyListener)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('admin', isAdmin)
  }, [isAdmin])

  const tracks = useMemo(
    () =>
      isAdmin
        ? trackList.sort((a, b) => new Date(b.date) - new Date(a.date))
        : trackList
            .map((track) =>
              track.rating === undefined ? { ...track, rating: 1 } : track,
            )
            .filter((track) => track.rating > 0)
            .sort((a, b) => (a.rating > b.rating ? -1 : 1)),
    [trackList, isAdmin],
  )

  function loadTrack(track) {
    audio = new Howl({
      src: [
        `${baseUrl}/webm/${track.filename}.webm`,
        `${baseUrl}/mp3/${track.filename}.mp3`,
      ],
      html5: true,
    })
    setAudioState(2)
    setCurrentTrack(track)
    currentTrackRef.current = track
  }

  const handlePlaying = () => {
    setAudioState(1)

    let playedTracks = []
    const playedTracksStored = JSON.parse(
      localStorage.getItem(PLAYED_TRACKS_KEY),
    )

    if (playedTracksStored) {
      playedTracks = [...playedTracksStored]
    }

    if (playedTracks.indexOf(currentTrackRef.current.filename) === -1) {
      playedTracks.push(currentTrackRef.current.filename)
      localStorage.setItem(PLAYED_TRACKS_KEY, JSON.stringify(playedTracks))
    }

    intervalId = setInterval(() => {
      setAudioPosition(audio.seek())
    }, PLAYBACK_POSITION_UPDATE_INTERVAL)
  }

  const handlePaused = () => {
    setAudioState(0)
    clearInterval(intervalId)
  }

  const handleEnded = () => {
    const trackIndex = tracks.findIndex(
      (track) => track.filename === currentTrackRef.current.filename,
    )

    if (trackIndex < tracks.length - 1) {
      const nextIndex = trackIndex + 1
      const nextTrack = tracks[nextIndex]
      handleTrackClicked(nextTrack)
    } else {
      handlePaused()
    }
  }

  useEffect(() => {
    if (audio) {
      audio.on('play', handlePlaying)
      audio.on('pause', handlePaused)
      audio.on('end', handleEnded)

      return () => {
        audio.off('play', handlePlaying)
        audio.off('pause', handlePaused)
        audio.off('end', handleEnded)
      }
    }
  }, [audio])

  const handleTrackClicked = useCallback(
    (track) => {
      if (audioState === 1) {
        if (currentTrack && currentTrack.filename === track.filename) {
          audio.seek(0)
          return
        } else {
          audio.stop()
        }
      }

      if (!currentTrack || currentTrack.filename !== track.filename) {
        if (audio) {
          setAudioPosition(0)
          audio.unload()
          audio = null
        }
        loadTrack(track)
      }

      audio.play()
    },
    [audioState, currentTrack],
  )

  const handlePlayToggleClicked = useCallback(() => {
    if (audioState === 1) {
      audio.pause()
    } else if (audioState === 0) {
      if (!currentTrack) {
        const firstTrack = tracks[0]
        loadTrack(firstTrack)
      }

      audio.play()
    }
  }, [audioState, currentTrack])

  const handleSeekClicked = useCallback(
    (unitInterval) => {
      if (currentTrack && audioState !== -1) {
        audio.seek(unitInterval * currentTrack.duration)

        if (audioState === 0 && currentTrack) {
          audio.play()
        }
      }
    },
    [audioState, currentTrack],
  )

  const playerContextValue = useMemo(
    () => ({
      currentTrack,
      audioState,
      handleTrackClicked,
      handlePauseClicked: handlePlayToggleClicked,
      handleSeekClicked,
      isAdmin,
    }),
    [
      currentTrack,
      audioState,
      handleTrackClicked,
      handlePlayToggleClicked,
      handleSeekClicked,
      isAdmin,
    ],
  )

  const settingsContextValue = useMemo(
    () => ({
      isAdmin,
    }),
    [isAdmin],
  )

  return (
    <PlayerContext.Provider value={playerContextValue}>
      <SettingsContext.Provider value={settingsContextValue}>
        <div id="title">nattonooto</div>
        {isAdmin ? <TracksAdmin tracks={tracks} /> : <Tracks tracks={tracks} />}
        <div id="video-container">
          <div id="video">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/gQVFa17CFAI"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <Visuals />
        <InfoContext.Provider value={{ isInfoOpen, setIsInfoOpen }}>
          {isInfoOpen && <Info />}
          <PlayerBar audioPosition={audioPosition} />
        </InfoContext.Provider>
      </SettingsContext.Provider>
    </PlayerContext.Provider>
  )
}

var mountNode = document.getElementById('app')
ReactDOM.render(<App />, mountNode)
