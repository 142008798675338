import React, { memo, useContext } from 'react'
import { getTime } from './common'
import { SettingsContext } from './index'

const dateOptions = { month: 'numeric', day: 'numeric', year: 'numeric' }

const TrackDetails = (props) => {
  const { track, date } = props

  const { isAdmin } = useContext(SettingsContext)

  const dateString = date.toLocaleDateString(undefined, dateOptions)

  return (
    <p>
      {isAdmin && (
        <>
          <span className={`filename`}>{track.filename}</span>
          {track.rating !== undefined && (
            <span className={`rating rating-${track.rating}`}>
              {track.rating}
            </span>
          )}
          <span className={`name`}>{track.name}</span>
          <span className={`notes`}>{track.notes}</span>
        </>
      )}
      <span className={`date`}>{dateString}</span>
      <span className={`duration`}>{getTime(track.duration)}</span>
    </p>
  )
}

function Track(props) {
  const { track, isCurrent, handleClicked } = props

  return (
    <button
      className={`track ${props.wasPlayed ? 'played' : ''} ${
        isCurrent ? 'playing' : ''
      }`}
      title="play track"
      onClick={() => handleClicked(track)}
    >
      <TrackDetails track={track} date={props.date} />
    </button>
  )
}

const areEqual = (prevProps, nextProps) =>
  prevProps.track.filename === nextProps.track.filename &&
  prevProps.isCurrent === nextProps.isCurrent

export default memo(Track, areEqual)
