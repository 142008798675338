import debounce from 'lodash.debounce'
import React, { useCallback, useEffect, useRef, useState } from 'react'

const tendencies = { n: ['a', 'o'], o: ['n', 'o'], t: ['t', 'o'], a: ['t'] }
const validLetters = Object.keys(tendencies)

const getLetters = (width, height) => {
  let letters = []

  const count = (width * height) / 250

  for (let i = 0; i < count; i++) {
    let letter
    let takeTendency = false
    if (i > 0) {
      takeTendency = Math.random() > 0.1
    }

    if (takeTendency) {
      const tendency = tendencies[letters[i - 1]]
      letter = tendency[Math.round(Math.random() * (tendency.length - 1))]
    } else {
      letter =
        validLetters[Math.round(Math.random() * (validLetters.length - 1))]
    }

    letters.push(letter)
  }

  return letters
}

const Visuals = () => {
  const [letters, setLetters] = useState([])
  const [prevLettersCalculationArea, setPrevLettersCalculationArea] = useState(
    0,
  )
  const areaRef = useRef(prevLettersCalculationArea)
  areaRef.current = prevLettersCalculationArea

  const calculateLetters = useCallback(() => {
    const { innerWidth, innerHeight } = window
    const area = innerWidth * innerHeight

    if (area > areaRef.current) {
      setPrevLettersCalculationArea(area)
      setLetters(getLetters(innerWidth, innerHeight))
    }
  }, [prevLettersCalculationArea])

  const calculateLettersDebounced = debounce(calculateLetters, 1000)

  useEffect(() => {
    calculateLetters()
    window.addEventListener('resize', calculateLettersDebounced)
    return () => {
      window.removeEventListener('resize', calculateLettersDebounced)
    }
  }, [])

  return (
    <div id="visuals">
      {letters.map((letter, index) => (
        <span key={index + letter} className={`letter letter-${letter}`}>
          {letter}
        </span>
      ))}
    </div>
  )
}

export default Visuals
