import React, { useContext } from 'react'
import { PLAYED_TRACKS_KEY } from './constants'
import { PlayerContext } from './index'
import Track from './Track'

function Tracks({ tracks }) {
  const { audioState, currentTrack, handleTrackClicked } = useContext(
    PlayerContext,
  )

  const playedTracks = JSON.parse(localStorage.getItem(PLAYED_TRACKS_KEY))

  return (
    <div id="tracks">
      {tracks.map((track) => {
        const date = new Date(track.date)

        const wasPlayed =
          playedTracks && playedTracks.indexOf(track.filename) > -1

        const isCurrent =
          currentTrack &&
          audioState !== -1 &&
          currentTrack.filename === track.filename

        return (
          <Track
            key={track.filename}
            track={track}
            date={date}
            wasPlayed={wasPlayed}
            isCurrent={isCurrent}
            handleClicked={handleTrackClicked}
          />
        )
      })}
    </div>
  )
}

export default React.memo(Tracks)
