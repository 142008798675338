import React, { createRef, useContext, useEffect, useState } from 'react'
import { getTime } from './common'
import { InfoContext, PlayerContext, SettingsContext } from './index'

function PlayerBar(props) {
  const {
    audioState,
    currentTrack,
    handlePauseClicked,
    handleSeekClicked,
  } = useContext(PlayerContext)
  const { isAdmin } = useContext(SettingsContext)
  const { isInfoOpen, setIsInfoOpen } = useContext(InfoContext)

  const { audioPosition } = props

  const [widthPercentage, setWidthPercentage] = useState(0)

  useEffect(() => {
    if (currentTrack) {
      setWidthPercentage((audioPosition / currentTrack.duration) * 100)
    }
  }, [audioPosition, currentTrack])

  const ref = createRef()

  const handleSeekClick = (event) => {
    if (ref.current) {
      const pos = event.clientX - ref.current.offsetLeft
      const unitInterval = pos / ref.current.clientWidth
      setWidthPercentage(unitInterval * 100)
      handleSeekClicked(unitInterval)
    }
  }

  const handleInfoClick = () => {
    setIsInfoOpen(!isInfoOpen)
  }

  return (
    <div id="bar">
      <div className="button" onClick={handlePauseClicked}>
        <span className={'icon' + audioState}>
          {audioState === 1 || audioState === 2 ? '||' : '>'}
        </span>
      </div>
      <div
        className={`cti-wrapper ${audioState === 2 ? 'loading' : ''}`}
        ref={ref}
        onClick={handleSeekClick}
      >
        {isAdmin && <span>{getTime(audioPosition)}</span>}
        <div className="cti" style={{ width: `${widthPercentage}%` }}></div>
      </div>
      <div className="button info" onClick={handleInfoClick}>
        i
      </div>
    </div>
  )
}

export default PlayerBar
